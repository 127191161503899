import {postRequest} from "./utils";
import {addToast} from "./toast";
import {_} from "svelte-i18n";
import {get} from "svelte/store";
import {defaultConst} from "../common/constants";

export async function subscribeProcess({id, currency, userToken, rate, org, provider, success_url = false}) {
    let params = {
        id, currency, userToken, rate, org, provider
    };
    if (success_url) {
        params.success_url = success_url;
    }

    let url = defaultConst.paymentUrl;

    if (provider === "yookassa") {
        url = defaultConst.functionPaymentProxyUrl;
    }

    let result = await postRequest(`${url}/subscribe/${provider}`, params);

    if (!result || result.error || !result.url) {
        if(result?.error?.urls){
            return result;
        }
        addToast({
            title: get(_)("error"),
            message: result.error || get(_)("something_went_wrong")
        });
    } else {
        if (provider === "stripe") {
            /*dist key - pk_test_51IOpMyHmQaZbF6i57IjzAfWC1ZwzRYz5WJT60aN2JHqzmlYI0xKqxrV7brxDrcsGLFznTZ8pcQKCpcgPKp0tBAix00dGGgIeCM*/
            /*msg key - pk_test_51LxyfcKzRtXrTd5YD6ALzzTrGaIGApiy2re0JF06lLJvIydFVZXtvQyv6dF88QiL50jXr5oezTRPIfiB1cWFF1ub00CDuBDmRe*/

            const stripe = Stripe('pk_live_51LxyfcKzRtXrTd5YEIZtN0Q18Q4NZL22WbhPAs4HT7771JN635eKuOyYgfqnNSOyLLbR3935Um7NEUgWOfCclsz600orXT85ou');
            stripe.redirectToCheckout({sessionId: result.url});
        }
        return result.url;
    }
    return  false;
};