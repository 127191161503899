<script>
  import {Modal, ModalBody, ModalHeader} from "sveltestrap";
  import {onMount, onDestroy} from "svelte";
  import {Link} from "svelte-routing";
  import {globalHistory} from "svelte-routing/src/history";

  import {_} from "svelte-i18n";
  import {channels, projects, user, chosenChannel} from "../../helpers/store";
  import {timer} from "../../helpers/utils";

  let current = "inbox";
  let current_child = "inbox";
  let unsub;

  let userChannels = [];
  let userProjects = [];

  let isMobile = false; //initiate as false
  // device detection
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
  }
  let isOpen = false;

  const unsub1 = channels.subscribe(async (value) => {
    if (value) {
      if (userChannels.length !== value.length) {
        userChannels = value;
        await timer(50);
        menuActivation();
      }
    }
  });

  const unsub2 = projects.subscribe(async (value) => {
    if (value) {
      let local = value;
      if (Array.isArray(local)) {
        local.sort((a, b) =>
          a.creationTS - b.creationTS
        );
      }
      userProjects = local;
      menuActivation();
    }
  });

  // function changeClassAttribute(attribute, child = null) {
  //   const dropMenuElement = document.getElementById(`dropmenu-${attribute}`);
  //   const subMenu = dropMenuElement.querySelector('.sub-menu');

  //   if (dropMenuElement.classList.contains('mm-dropmenu')) {
  //     if (subMenu.classList.contains('mm-show')) {
  //       dropMenuElement.classList.remove('mm-dropmenu');
  //       subMenu.classList.remove('mm-show');
  //     }
  //   } else {
  //     dropMenuElement.classList.add('mm-dropmenu');
  //     subMenu.classList.add('mm-show');
  //   }

  //   return false;
  // }

  onDestroy(() => {
    try{
      unsub();
      unsub1();
      unsub2();
    } catch (e) {}
  });

  onMount(() => {
    unsub = globalHistory.listen(({location, action}) => {
      menuActivation();
    });

    menuActivation();
  });

  function menuActivation() {
    current = window.location.pathname;

    let links = document.getElementsByClassName("side-nav-link-ref");
    let active = document.querySelectorAll(".vertical-menu .mm-active, .vertical-menu .active");
    for (let i = 0; i < active.length; i++) {
      active[i].classList.remove("active", "mm-active");
    }

    let matchingMenuItem = null;
    const paths = [];

    for (let i = 0; i < links.length; i++) {
      paths.push(links[i].getAttribute('data-link'));
    }
    let itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      // const strIndex = window.location.pathname.lastIndexOf("/");
      // const item = window.location.pathname.substr(0, strIndex).toString();
      let item = paths.filter(val => window.location.pathname.includes(val));
      if (item.length !== 1) {
        item = item.find(val => window.location.pathname === val);
      } else {
        item = item[0];
      }
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");

      let parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.closest("li")?.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu" && !isMobile) {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            parent3.classList.add("mm-dropmenu");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  const menuBusiness = [
    {element: 'menu-title', check: true, text: 'menuitems.menu.shared_inbox'},
    {element: 'link', check: true, to: 'inbox', dataLink: '/inbox', icon: 'bx bx-chat', text: 'menuitems.chat_inbox.text'},
    {element: 'link', check: true, to: 'mobile-app', dataLink: '/mobile-app', icon: 'bx bx-mobile-vibration', text: 'menuitems.mobile_app.text'},
    {element: 'link', check: true, to: 'contacts', dataLink: '/contacts', icon: 'bx bx-group', text: 'menuitems.contacts.text'},
    {element: 'link', check: !$user.isOperator, to: 'leads', dataLink: '/leads', icon: 'bx bx-user-pin', text: 'menuitems.leads.text'},
    {element: 'link', check: true, to: 'whatsapp-link-generator', dataLink: '/whatsapp-link-generator', icon: 'bx bx-link-alt', text: 'menuitems.walinkgenerator.text'},
    {element: 'link', check: true, to: 'whatsapp-chat-button', dataLink: '/whatsapp-chat-button', icon: 'bx bxs-widget', text: 'menuitems.wachatbutton.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.automation.text'},
    {element: 'link', check: !$user.isOperator, to: 'automation/flow', dataLink: '/automation/flow', icon: 'bx bx-share-alt', text: 'menuitems.automation.list.flow_builder'},
    {element: 'link', check: !$user.isOperator, to: 'automation/flow-library', dataLink: '/automation/flow-library', icon: 'mdi mdi-library', text: 'menuitems.automation.list.flow_library'},
    {element: 'link', check: !$user.isOperator, to: 'automation/auto-reply', dataLink: '/automation/auto-reply', icon: 'bx bx-message-square-dots', text: 'menuitems.automation.list.auto_replies'},
    {element: 'link', check: !$user.isOperator, to: 'automation/keyword', dataLink: '/automation/keyword', icon: 'bx bx-book-content', text: 'menuitems.automation.list.keyword_action'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.notifications'},
    {element: 'link', check: !$user.isOperator, to: 'broadcast', dataLink: '/broadcast', icon: 'bx bx-broadcast', text: 'menuitems.broadcasts.text', new: true},
    {element: 'link', check: !$user.isOperator, to: 'segments', dataLink: '/segments', icon: 'bx bx-pie-chart-alt-2', text: 'menuitems.segments.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.developers'},
    {element: 'link', check: !$user.isOperator, to: 'testing/send-message', dataLink: '/testing', icon: 'bx bx-desktop', text: 'menuitems.sandbox'},
    {element: 'link', check: !$user.isOperator, to: 'documentation', dataLink: '/documentation', icon: 'bx bx-file-find', text: 'menuitems.documentation.text'},
    {element: 'link', check: !$user.isOperator && $user.admin, to: 'admintoolbox', dataLink: '/admintoolbox', icon: 'bx bx-file-find', text: 'admintoolbox'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.configuration'},
    {element: 'link', check: !$user.isOperator, to: 'channels', dataLink: '/channels', icon: 'bx bx-chip', text: 'menuitems.channels.text'},
    {element: 'link', check: !$user.isOperator, to: 'roles', dataLink: '/roles', icon: 'bx bx-user-plus', text: 'menuitems.users_and_roles.text'},
    {element: 'link', check: !$user.isOperator, to: 'routing', dataLink: '/routing', icon: 'bx bx-shuffle', text: 'menuitems.routing.text'},

    {element: 'menu-title', check: true, text: 'menuitems.menu.settings'},
    {element: 'link', check: !$user.isOperator, to: 'billing', dataLink: '/billing', icon: 'bx bx-credit-card', text: 'menuitems.billing.text'},
    {element: 'link', check: true, to: 'support', dataLink: '/support', icon: 'bx bx-support', text: 'menuitems.support.text'},
    {element: 'a', check: true, href: 'menuitems.help_center.href', icon: 'bx bx-help-circle', text: 'menuitems.help_center.text'},
    {element: 'a', check: true, href: 'https://1msg.io/blog/', icon: 'bx bx-detail', text: 'menuitems.blog.text'},
  ];

  const menuCoder = [
    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.developers'},
    {element: 'link', check: !$user.isOperator, to: 'testing/send-message', dataLink: '/testing', icon: 'bx bx-desktop', text: 'menuitems.sandbox'},
    {element: 'link', check: !$user.isOperator, to: 'sdk-generator', dataLink: '/sdk-generator', icon: 'fas fa-dice-d6', text: 'menuitems.sdk'},
    {element: 'link', check: !$user.isOperator, to: 'documentation', dataLink: '/documentation', icon: 'bx bx-file-find', text: 'menuitems.documentation.text'},
    {element: 'link', check: !$user.isOperator && $user.admin, to: 'admintoolbox', dataLink: '/admintoolbox', icon: 'bx bx-file-find', text: 'admintoolbox'},

    {element: 'menu-title', check: true, text: 'menuitems.menu.settings'},
    {element: 'link', check: !$user.isOperator, to: 'billing', dataLink: '/billing', icon: 'bx bx-credit-card', text: 'menuitems.billing.text'},
    {element: 'link', check: true, to: 'support', dataLink: '/support', icon: 'bx bx-support', text: 'menuitems.support.text'},
    {element: 'a', check: true, href: 'menuitems.help_center.href', icon: 'bx bx-help-circle', text: 'menuitems.help_center.text'},
    {element: 'a', check: true, href: 'https://1msg.io/blog/', icon: 'bx bx-detail', text: 'menuitems.blog.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.configuration'},
    {element: 'link', check: !$user.isOperator, to: 'channels', dataLink: '/channels', icon: 'bx bx-chip', text: 'menuitems.channels.text'},
    {element: 'link', check: !$user.isOperator, to: 'roles', dataLink: '/roles', icon: 'bx bx-user-plus', text: 'menuitems.users_and_roles.text'},
    {element: 'link', check: !$user.isOperator, to: 'routing', dataLink: '/routing', icon: 'bx bx-shuffle', text: 'menuitems.routing.text'},

    {element: 'menu-title', check: true, text: 'menuitems.menu.shared_inbox'},
    {element: 'link', check: true, to: 'inbox', dataLink: '/inbox', icon: 'bx bx-chat', text: 'menuitems.chat_inbox.text'},
    {element: 'link', check: true, to: 'mobile-app', dataLink: '/mobile-app', icon: 'bx bx-mobile-vibration', text: 'menuitems.mobile_app.text'},
    {element: 'link', check: true, to: 'contacts', dataLink: '/contacts', icon: 'bx bx-group', text: 'menuitems.contacts.text'},
    {element: 'link', check: !$user.isOperator, to: 'leads', dataLink: '/leads', icon: 'bx bx-user-pin', text: 'menuitems.leads.text'},
    {element: 'link', check: true, to: 'whatsapp-link-generator', dataLink: '/whatsapp-link-generator', icon: 'bx bx-link-alt', text: 'menuitems.walinkgenerator.text'},
    {element: 'link', check: true, to: 'whatsapp-chat-button', dataLink: '/whatsapp-chat-button', icon: 'bx bxs-widget', text: 'menuitems.wachatbutton.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.automation.text'},
    {element: 'link', check: !$user.isOperator, to: 'automation/flow', dataLink: '/automation/flow', icon: 'bx bx-share-alt', text: 'menuitems.automation.list.flow_builder'},
    {element: 'link', check: !$user.isOperator, to: 'automation/flow-library', dataLink: '/automation/flow-library', icon: 'mdi mdi-library', text: 'menuitems.automation.list.flow_library'},
    {element: 'link', check: !$user.isOperator, to: 'automation/auto-reply', dataLink: '/automation/auto-reply', icon: 'bx bx-message-square-dots', text: 'menuitems.automation.list.auto_replies'},
    {element: 'link', check: !$user.isOperator, to: 'automation/keyword', dataLink: '/automation/keyword', icon: 'bx bx-book-content', text: 'menuitems.automation.list.keyword_action'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.notifications'},
    {element: 'link', check: !$user.isOperator, to: 'broadcast', dataLink: '/broadcast', icon: 'bx bx-broadcast', text: 'menuitems.broadcasts.text', new: true},
    {element: 'link', check: !$user.isOperator, to: 'segments', dataLink: '/segments', icon: 'bx bx-pie-chart-alt-2', text: 'menuitems.segments.text'},
  ];

  const menuDeveloper = [
    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.developers'},
    {element: 'link', check: !$user.isOperator, to: 'testing/send-message', dataLink: '/testing', icon: 'bx bx-desktop', text: 'menuitems.sandbox'},
    {element: 'link', check: !$user.isOperator, to: 'sdk-generator', dataLink: '/sdk-generator', icon: 'fas fa-dice-d6', text: 'menuitems.sdk'},
    {element: 'link', check: !$user.isOperator, to: 'documentation', dataLink: '/documentation', icon: 'bx bx-file-find', text: 'menuitems.documentation.text'},
    {element: 'link', check: !$user.isOperator && $user.admin, to: 'admintoolbox', dataLink: '/admintoolbox', icon: 'bx bx-file-find', text: 'admintoolbox'},

    {element: 'menu-title', check: true, text: 'menuitems.menu.settings'},
    {element: 'link', check: !$user.isOperator, to: 'billing', dataLink: '/billing', icon: 'bx bx-credit-card', text: 'menuitems.billing.text'},
    {element: 'link', check: true, to: 'support', dataLink: '/support', icon: 'bx bx-support', text: 'menuitems.support.text'},
    {element: 'a', check: true, href: 'menuitems.help_center.href', icon: 'bx bx-help-circle', text: 'menuitems.help_center.text'},
    {element: 'a', check: true, href: 'https://1msg.io/blog/', icon: 'bx bx-detail', text: 'menuitems.blog.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.configuration'},
    {element: 'link', check: !$user.isOperator, to: 'channels', dataLink: '/channels', icon: 'bx bx-chip', text: 'menuitems.channels.text'},

    {element: 'menu-title', check: true, text: 'menuitems.menu.shared_inbox'},
    {element: 'link', check: true, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.chat_inbox.text'},
    {element: 'link', check: true, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.mobile_app.text'},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.leads.text'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.automation.text'},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.automation.list.flow_builder'},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.automation.list.flow_library'},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.automation.list.auto_replies'},

    {element: 'menu-title', check: !$user.isOperator, text: 'menuitems.menu.notifications'},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.broadcasts.text', new: true},
    {element: 'link', check: !$user.isOperator, to: 'upgrade', dataLink: '/upgrade', icon: 'bx bx-lock-alt', text: 'menuitems.segments.text'},
  ];

  let sidebarMenu;

  $: if(sidebarMenu) {
    let scrollTop = localStorage.getItem('scrollTopSidebarMenu');
    if (!scrollTop) {
      localStorage.setItem('scrollTopSidebarMenu', 0);
    }
    sidebarMenu.scrollTop = scrollTop;
    sidebarMenu.addEventListener('scroll', () => {
      localStorage.setItem('scrollTopSidebarMenu', sidebarMenu.scrollTop);
    });
  };

  const closeMenu = () => {
    let body = document.body;
    if (body.classList.contains('sidebar-enable') && body.classList.contains('vertical-collpsed')) {
      body.classList.remove("sidebar-enable", "vertical-collpsed");
    }
  };
</script>

<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu {$user.trialCreated || $user.isOperator? '' : 'd-none'}">
  <div data-simplebar class="h-100">
    <!--- Sidemenu -->
    <div id="sidebar-menu" bind:this={sidebarMenu}>
      <!-- {#if $chosenChannel?.noAutoCharge}
        <div class="mb-3 p-2 px-xl-3 py-xl-2 warning">
          {$_('menuitems.warning.title')}
          <span class="text-decoration-underline pointer" on:click={() => isOpen = true}>{$_('menuitems.warning.btn')}</span>
        </div>
      {/if} -->
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">
        {#each ($user.isDeveloper === true? menuDeveloper : $user.generalCompanyType === 0 ? menuCoder : menuBusiness) as item}
          {#if item.element === 'menu-title' && item.check}
            <li class="menu-title">{$_(item.text)}</li>
          {/if}
          {#if item.element === 'link' && item.check}
            <li>
              <Link to={item.to} class="waves-effect side-nav-link-ref align-items-center" data-link={item.dataLink} on:click={closeMenu}>
                <i class={item.icon}/>
                <span>{$_(item.text)}</span>
                {#if item?.new}
                  <div class="badge rounded-pill bg-success ms-auto mt-0">New</div>
                {/if}
              </Link>
            </li>
          {/if}
          {#if item.element === 'a' && item.check}
            <li>
              <a href={$_(item.href)} target="_blank" class="waves-effect side-nav-link-ref align-items-center">
                <i class={item.icon}/>
                <span>{$_(item.text)}</span>
              </a>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
    <!-- Sidebar -->
  </div>
</div>
<!-- Left Sidebar End -->

{#if isOpen}
  <Modal isOpen={isOpen} fade={true} toggle={() => isOpen = false} size="lg" centered>
    <ModalHeader toggle={() => isOpen = false} class="border-bottom-0 pb-0 align-items-start">
      <span class="font-size-18">
        {$_("menuitems.warning.subtitle")}
      </span>
    </ModalHeader>
    <ModalBody>
      <div class="mb-4 font-size-14">
        {@html $_("menuitems.warning.info")}
      </div>
    </ModalBody>
  </Modal>
{/if}

<style>
  .warning {
    background-color: #FDE1E1;
    color: #F46A6A;
    border-radius: 6px;
    margin-left: -11px;
  }

  @media (max-width: 1199px) {
    .warning {
      border-radius: 0;
      margin-left: 0;
    }
  }
</style>