<script>
  import {_} from "svelte-i18n";
  import {defaultConst} from "../common/constants";
  import {corporate, isUserCreatedAfter1July2024} from "../helpers/store";
  import {postRequest} from "../helpers/utils";
  import {addToast} from "../helpers/toast";
  import BillingDetails from "../pages/Billing/BillingDetails.svelte";

  export let uid;
  export let rate;
  export let currency;
  export let org;
  export let channelId;
  export let userToken;
  export let loading;
  export let classes = 'btn btn-lucky-primary';
  export let styles = '';

  let loadingCorporateInvoiceHosting = false;
  let modalBillingDetails = {
    isOpen: false,
    data: null
  };

  function corporateInvoiceHosting() {
    let data = {
      url: `${defaultConst.functionPaymentProxyUrl}/corporate-invoice/hosting`,
      params: {uid, rate, currency, channelId, userToken}
    };

    if (isUserCreatedAfter1July2024()) {
      data.params.org = org;
    }

    if (!$corporate) {
      modalBillingDetails = {isOpen: true, data}
      return false;
    }

    loadingCorporateInvoiceHosting = true;
    postRequest(data.url, data.params, true).then(res => {
      loadingCorporateInvoiceHosting = false;
      if (res.ok) {
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          let filename = res.headers.get('Content-Disposition').split(';').pop().split('"')[1];
          link.download = `corporate-invoice-${filename}`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            title: "Успех",
            message: "Счет на оплату создан"
          });
        });
      } else {
        addToast({
          title: $_("error"),
          message: res.error || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000
        });
      }
    });
  }
</script>

<button class={classes} style={styles} type="button"
  on:click={corporateInvoiceHosting} disabled={loadingCorporateInvoiceHosting || loading}>
  {#if loadingCorporateInvoiceHosting}
    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
  {/if}
  {$_('payments.pay_by_invoice')}
</button>
<BillingDetails card={false} bind:modalBillingDetails/>
