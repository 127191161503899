<script>
  // import {
  //   Form,
  //   Input,
  //   Row,
  //   Col,
  //   Dropdown,
  //   DropdownToggle,
  //   DropdownMenu,
  // } from "sveltestrap";
  import {_} from "svelte-i18n";
  // import {Link} from "svelte-routing";

  import LanguageDropdown from "../../common/LanguageDropdown.svelte";
  import NotificationDropdown from "../../common/NotificationDropdown.svelte";
  import ProfileMenu from "../../common/ProfileMenu.svelte";
  import ProjectsDropdown from "../../Components/ProjectsDropdown.svelte";
  // import RightsidebarButton from "../../Components/RightsidebarButton.svelte";
  import TrialHeaderModal from "../../Components/Modals/TrialHeaderModal.svelte";
  import {addChannelFirstTimeHelper, toggleTrialRestrictionsModal, toolbarLoad} from "../../helpers/store";
  import UserHintsOffcanvasContainer from "../../Components/UserHints/UserHintsOffcanvasContainer.svelte";
  import SpinnerComponent from "../../Components/Spinners/SpinnerComponent.svelte";
  import {Link} from "svelte-routing";
  import {navigate} from "svelte-routing";
  import {get} from "svelte/store";

  export let alert;
  let loading = false;
  let verticalMenuBtnStatus = false;

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    let body = document.body;

    body.classList.toggle("sidebar-enable");
    body.classList.toggle("vertical-collpsed");

    if (window.innerWidth >= 992) {
      if (body.getAttribute("data-sidebar-size") == "sm") {
        body.setAttribute("data-sidebar-size", "lg");
      } else {
        body.setAttribute("data-sidebar-size", "sm");
      }
    }
    verticalMenuBtnStatus = !verticalMenuBtnStatus;
  }

  // export let sidebar = false;


  let showTrialModal = false;

  async function createChannel() {
    if (!loading) {
      let res = addChannelFirstTimeHelper(()=>{
        const channelsLocal = get(channels)?.filter(item => item.srv !== 'trial')
        if (channelsLocal?.length > 0)
          navigate(`/channel/${channelsLocal?.[0]?.id}`)
      })
      if (res) {
        loading = true;
        res.then(() => {
          loading = false
        }, () => {
          loading = false
        })
      }
    }
  }
</script>

<header id="page-topbar">
  {#if window.innerWidth > 1220}
    {#if alert?.show}
      <div class="trial_alert text-center w-100">
        <div class="text-secondary font-size-12 d-inline me-2">
          <i class="mdi mdi-alert-circle-outline"/>
          {@html $_('trial.expire', {
            values: {
              days: alert.expireDays,
              phone: '+6531743440' || alert.phone
            }
          })}
        </div>
        <button type="button" class="btn bg-green btn-sm px-3 text-white" on:click={createChannel}>
          {$_('trial.extend_btn')}
          {#if loading}
            <SpinnerComponent class="ssm"/>
          {/if}
        </button>
        <div class="text-secondary font-size-12 d-inline">
          {$_("support.or")}
        </div>
        <a href={$_("trial_header.manager_url")} target="_blank" class="btn btn-lg btn-outline-success btn-sm px-3" type="button">
          {$_("trial_header.btn1")}
        </a>
      </div>
    {/if}
  {:else}
    {#if alert?.show}
      <div class="trial_alert text-center w-100">
        <div class="text-secondary font-size-12 d-inline me-1">
          <i class="mdi mdi-alert-circle-outline"/>
          {@html $_('trial.expire2', {
            values: {
              days: alert.expireDays,
              phone: '+6531743440' || alert.phone
            }
          })}
        </div>
        <button type="button" class="btn bg-green btn-sm px-1 text-white" on:click={createChannel}>
          {$_('trial.extend_btn2')}
          {#if loading}
            <SpinnerComponent class="ssm"/>
          {/if}
        </button>
      </div>
    {/if}
  {/if}
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <Link class="logo logo-light" to="/">
          <span class="logo-sm">
            <img alt="logo" height="40" src="/assets/images/logo-1msg-dark-mini.svg"/>
          </span>
          <span class="logo-lg">
            <img alt="logo" height="40" src="/assets/images/logo-1msg-dark.svg"/>
          </span>
        </Link>
      </div>

      <button
        class="btn btn-sm px-3 font-size-16 vertinav-toggle header-item waves-effect d-xl-none"
        id="vertical-menu-btn" on:click={tToggle}>
        {#if verticalMenuBtnStatus}
          <i class="fa fa-fw fa-times"/>
        {:else}
          <i class="fa fa-fw fa-bars"/>
        {/if}
      </button>

      <ProjectsDropdown/>

      <!-- App Search-->
      <!-- <Form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <Input
            type="text"
            class="form-control"
            placeholder={$_("navbar.search.text")}
          />
          <span class="bx bx-search-alt" />
        </div>
      </Form> -->
      {#if $toolbarLoad}
        <div class="pt-3">
          <div class="spinner-border text-primary m-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      {/if}

    </div>

    <div class="d-flex">
      <ProfileMenu/>
      <!-- <Dropdown class="d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon headerbtn"
          on:click={toggleFullscreen}
          data-toggle="fullscreen"
        >
          <i class="bx bx-fullscreen" />
        </button>
      </Dropdown> -->
      <NotificationDropdown/>
      <!--      <div class="dropdown d-inline-block">-->
      <!--        <RightsidebarButton bind:open={sidebar} />-->
      <!--      </div>-->
      <LanguageDropdown/>
      <UserHintsOffcanvasContainer/>
    </div>
  </div>
</header>

<TrialHeaderModal bind:show={showTrialModal}/>

<style>
  .trial_alert {
      background: #FBEACD;
      z-index: 1;
      position: relative;
      padding: 7px;
      white-space: nowrap;
      overflow-x: auto;
  }

  .trial_alert::-webkit-scrollbar {
      height: 0;
  }

  /* новогодний стиль */
  /* @media screen and (min-width: 1199px) {
    .navbar-brand-box {
      position: relative;
    }

    .navbar-brand-box::after {
      content: '';
      position: absolute;
      bottom: -26px;
      width: 100%;
      max-width: 280px;
      height: 70px;
      background-image: url('/assets/images/icicles1.png');
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }

    .icicles-wrapper {
      position: relative;
    }

    .icicles-wrapper::after {
      content: '';
      position: absolute;
      bottom: -34px;
      right: -16px;
      width: 100%;
      max-width: 340px;
      height: 54px;
      background-image: url('/assets/images/icicles2.png');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }
  } */

</style>
