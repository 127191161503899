<script>
  import {Container, Row, Col, Button, Card, CardBody, CardTitle, FormGroup, Input, Spinner, Modal, ModalHeader, ModalBody,
    Dropdown, DropdownToggle, DropdownMenu} from "sveltestrap";
  import {db} from '../../helpers/firebase';
  import {onMount, onDestroy} from "svelte";
  import {balance, user, corporate} from "../../helpers/store";
  import {layout} from "../../services/loaders";
  import {_, locale} from "svelte-i18n";
  import {addToast} from "../../helpers/toast";
  import {getRequest, postRequest} from "../../helpers/utils";
  import {defaultConst} from "../../common/constants";
  import {navigate} from 'svelte-routing';
  import BillingDetails from "../Billing/BillingDetails.svelte";

  layout(true);
  const settings = {
    'USD': {
      min: 10,
      initAmount: 200,
      currency: 'usd',
      amounts: [
        30, 60, 100, 150
      ]
    },
    'RUB': {
      min: 100,
      initAmount: 1000,
      currency: 'rub',
      amounts: [
        3000, 5000, 7000, 15000
      ]
    },
    // 'INR': { // Индийская рупия
    //   min: 1000,
    //   initAmount: 2000,
    //   currency: 'inr',
    //   amounts: [
    //      2000, 3000, 5000, 7000
    //   ]
    // }
  }
  let activeBalance,
      loading = false,
      loadingCorporateInvoiceTemplates = false,
      modalBillingDetails = {
        isOpen: false,
        data: null
      },
      formSettings = false,
      // userChannels,
      amount,
      reminderLimit,
      notificationBalanceStatuLoader,
      balanceEmails,
      // rates,
      lang,
      templatesSum = {
        inr: 0,
        rub: 0,
        usd: 0
      },
      templatesSumLoader = true,
      paymentHistory,
      paymentHistoryLoader = true,
      isOpenDetails = false;

  onMount(async () => {
    if (localStorage.getItem("I18N_LANGUAGE")) {
      lang = localStorage.getItem("I18N_LANGUAGE");
    }
  });

  async function initSettings() {
    // if (!rates && userChannels) {
      // let activeChannels = userChannels.filter((item) => {
      //   return item.waba_info && !item.stopped;
      // }) || [];

      // if (activeChannels?.length) {
      //   let promises = [getStats(activeChannels)];
      //   promises.push(getRequest(`${defaultConst.paymentUrl}/exchange-rates`));

      //   let [statTmp, rateTmp] = await Promise.all(promises);
      //   rates = rateTmp;
      //   if (rates?.rub && statTmp.all?.total_price) {
      //     if (!settings['RUB'].amounts.includes(Math.ceil(statTmp.all.total_price * rates?.rub))) {
      //       settings['RUB'].amounts.push(Math.ceil(statTmp.all.total_price * rates?.rub));
      //     }
      //     if (!settings['USD'].amounts.includes(Math.ceil(statTmp.all.total_price))){
      //       settings['USD'].amounts.push(Math.ceil(statTmp.all.total_price));
      //     }
      //     if (!settings['INR'].amounts.includes(Math.ceil(statTmp.all.total_price * rates?.inr))) {
      //       settings['INR'].amounts.push(Math.ceil(statTmp.all.total_price * rates?.inr));
      //     }
      //   }
      // }
      // unsub3();
    // }
    if (activeBalance && lang) {
      formSettings = activeBalance?.activeCurrency && settings[activeBalance?.activeCurrency] || lang === 'ru' && settings['RUB'] || settings['USD'];
      if (!amount) amount = formSettings.amounts[0];
    }
  }

  // async function getStats(activeChannels) {
  //   let promises = [];

  //   for (let channel of activeChannels) {
  //     promises.push(getRequest(`${channel.apiUrl}statistics2`, {
  //       token: channel.token,
  //     }).then((data) => {
  //       return {
  //         channel: channel.id,
  //         data
  //       }
  //     }));
  //   }
  //   let result = await Promise.all(promises);

  //   let tmpStats = {
  //     all: {},
  //   };

  //   for (let stat of result) {
  //     if(stat.data.length){
  //       tmpStats[stat.channel] = stat.data[stat.data.length - 2 >= 0 && stat.data.length - 2 || 0 ];
  //       Object.keys(tmpStats[stat.channel]).forEach(key => {
  //         tmpStats.all[key] = parseFloat(tmpStats[stat.channel][key]) + (tmpStats.all[key] || 0)
  //       })
  //     }
  //   }
  //   return tmpStats;
  // }

  function reqTemplatesSum() {
    templatesSumLoader = true;
    getRequest(`${defaultConst.paymentUrl}/templates-sum`, {uid: activeBalance.ownerId, userToken: $user.userToken}).then(data => {
     templatesSum = data;
     templatesSumLoader = false;
    });
  }

  function getPaymentHistory() {
    paymentHistoryLoader = true;
    postRequest(`${defaultConst.functionsUrl}/getPaymentHistory`, {
      uid: activeBalance.ownerId, // month: '05.2024'
    }).then(data => {
      if (data?.result) {
        paymentHistory = data.result;
        paymentHistory.channels = Object.entries(paymentHistory.channels).map(([id, sum]) => ({id, sum}));
      }
      paymentHistoryLoader = false;
    });
  }

  const unsub = balance.subscribe(async value => {
    if (value) {
      activeBalance = value;
      reminderLimit = activeBalance?.reminderLimit;
      if (activeBalance?.emails) {
        balanceEmails = activeBalance?.emails.join(',');
      } else {
        balanceEmails = null;
      }
      reqTemplatesSum();
      getPaymentHistory();
      layout(false);
    }
  });

  const unsub2 = locale.subscribe(value => {
    if (value) {
      lang = value;
    }
  });

  // const unsub3 = channels.subscribe(value => {
  //   if (value) {
  //     userChannels = value;
  //   }
  // });

  onDestroy(() => {
    unsub();
    unsub2();
  });

  $: if (activeBalance && lang) {
    initSettings();
  }

  async function setNotificationBalanceStatus() {
    let arrBalanceEmails;
    if (balanceEmails?.length) {
      arrBalanceEmails = balanceEmails.replaceAll(' ', '').split(',');
      arrBalanceEmails = arrBalanceEmails.filter(el => el != null && el != "" || el === 0);
    } else {
      arrBalanceEmails = null;
    }

    if (arrBalanceEmails?.length > 3) {
      addToast({
        title: $_("error"),
        message: $_("balance.limitation_emails")
      });
      return;
    }

    notificationBalanceStatuLoader = true;
    let user_id = activeBalance.ownerId;
    let balanceRef = db.collection('balances').doc(user_id);
    await db.runTransaction(async (t) => {
      t.update(balanceRef, {reminderLimit, emails: arrBalanceEmails});
      setTimeout(() => {
        notificationBalanceStatuLoader = false;
      }, 1000);
    });
  }

  async function topUp() {
    if(amount < formSettings.min) {
      return addToast({
        title: $_("error"),
        message: $_("errors.wrong_value")
      });
    }

    if (loading) return;
    loading = true;

    let params = {
      amount: amount,
      currency: formSettings.currency.toUpperCase(),
      uid: activeBalance.ownerId
    };

    let provider = formSettings.currency === 'rub' && 'yookassa' || 'stripe';
    let url = defaultConst.paymentUrl;
    if (provider === 'yookassa') {
      url = defaultConst.functionPaymentProxyUrl;
    }
    let result = await postRequest(`${url}/balance-payment/${provider}`, params);

    loading = false;
    if (!result || result.error) {
      addToast({
        title: $_("error"),
        message: result.error || $_("something_went_wrong")
      });
    } else {
      if (provider === "stripe" && result?.url) {
        /*dist key - pk_test_51IOpMyHmQaZbF6i57IjzAfWC1ZwzRYz5WJT60aN2JHqzmlYI0xKqxrV7brxDrcsGLFznTZ8pcQKCpcgPKp0tBAix00dGGgIeCM*/
        /*msg key - pk_test_51LxyfcKzRtXrTd5YD6ALzzTrGaIGApiy2re0JF06lLJvIydFVZXtvQyv6dF88QiL50jXr5oezTRPIfiB1cWFF1ub00CDuBDmRe*/
        const stripe = Stripe('pk_live_51LxyfcKzRtXrTd5YEIZtN0Q18Q4NZL22WbhPAs4HT7771JN635eKuOyYgfqnNSOyLLbR3935Um7NEUgWOfCclsz600orXT85ou');
        return stripe.redirectToCheckout({sessionId: result.url});
      } else if (provider === "yookassa" && result?.url) {
        return  window.location = result.url;
      }
      addToast({
        title: $_("error"),
        message: $_("something_went_wrong")
      });
    }
  }

  function corporateInvoiceTemplates() {
    let data = {
      url: `${defaultConst.functionPaymentProxyUrl}/corporate-invoice/templates`,
      params: {
        uid: activeBalance.ownerId,
        amount: amount,
        currency: formSettings.currency.toUpperCase(),
        userToken: $user.userToken
      }
    };

    if (!$corporate) {
      modalBillingDetails = {isOpen: true, data}
      return false;
    }

    if(amount < formSettings.min) {
      return addToast({
        title: $_("error"),
        message: $_("errors.wrong_value")
      });
    }

    loadingCorporateInvoiceTemplates = true;
    postRequest(data.url, data.params, true).then(res => {
      loadingCorporateInvoiceTemplates = false;
      if (res.ok) {
        res.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          let filename = res.headers.get('Content-Disposition').split(';').pop().split('"')[1];
          link.download = `corporate-invoice-${filename}`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          setTimeout(() => addToast({
            title: "Успех",
            message: "Счет на оплату создан"
          }), 500);
          navigate("/billing");
        });
      } else {
        addToast({
          title: $_("error"),
          message: res.error || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000
        });
      }
    });
  }
</script>

{#if activeBalance}
    <div class="page-content">
        <Container fluid>
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <div class="header-title">
                        {$_("balance.title")}
                    </div>
                </div>
            </div>
            <Row>
                <Col sm="12" md="8" xl="6" xxl="4">
                    <Card>
                        <CardTitle class="m-3 mb-0 font-size-16 p-1" >
                            {$_("balance.form.title")}
                        </CardTitle>
                        <CardBody>
                            {#if formSettings}
                                <form on:submit|preventDefault={topUp}>
                                    <div class="form-group mb-3 ">
                                        <label class="fg-solitude-grey font-size-14 fw-500 w-100" for="amount">{$_('balance.form.amount')}</label>
                                        <div class="position-relative">
                                            <input type="number" min={formSettings.min} id="amount" bind:value={amount} placeholder={0} required class="form-control ps-4">
                                            <div class="position-absolute top-0  font-size-16 p-2 ">{$_(formSettings.currency)}</div>
                                        </div>
                                        <div class="fg-bs-gray mt-2">
                                            {$_('balance.form.minimal')} {$_(formSettings.currency)}{formSettings.min}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-start mb-2 flex-wrap">
                                        {#each formSettings.amounts as val}
                                            <button type="button" class="fg-green btn btn-sm btn-outline-success font-size-13 rounded-pill px-3 me-3 mb-1"
                                              on:click={() => amount = val}>
                                              {$_(formSettings.currency)} {val}
                                            </button>
                                        {/each}
                                    </div>
                                    <div class="alert alert-warning fg-indian-yellow font-size-13 ps-3 pe-1 py-2" >
                                        {$_('balance.form.warn')}
                                    </div>
                                    <div class="d-flex justify-content-between align-items-end flex-wrap gap-2">
                                        <div class="fg-bs-gray-700  fw-500 ">
                                            <div class="font-size-16">
                                                {$_('balance.form.summary')}
                                            </div>
                                            <div class="font-size-32">
                                                {$_(formSettings.currency)} {amount || 0}
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap gap-2">
                                          <Button color="success" class="px-3 font-size-14"
                                              disabled={loading || loadingCorporateInvoiceTemplates || amount < formSettings.min}>
                                              {#if loading}
                                                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                                              {/if}
                                              {$_('balance.form.top_up')}
                                          </Button>
                                          {#if lang === 'ru'}
                                            <Button color="primary" class="px-3 font-size-14" type="button"
                                                on:click={corporateInvoiceTemplates}
                                                disabled={loadingCorporateInvoiceTemplates || loading || amount < formSettings.min}>
                                                {#if loadingCorporateInvoiceTemplates}
                                                  <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
                                                {/if}
                                                {$_('payments.pay_by_invoice')}
                                            </Button>
                                            <BillingDetails card={false} bind:modalBillingDetails/>
                                          {/if}
                                        </div>
                                    </div>
                                </form>
                            {/if}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="8" xl="6" xxl="4">
                  <Card>
                      <CardBody class="position-relative">
                        {#if templatesSumLoader && paymentHistoryLoader}
                          <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-white">
                            <Spinner/>
                          </div>
                        {/if}
                        <CardTitle class="font-size-14">
                            {$_("balance.amount_dialogues")}
                        </CardTitle>
                        <div class:mb-3={lang === 'ru' && paymentHistory?.correction}>
                          <span class="font-size-24 pe-2">$ {templatesSum.usd}</span>
                          {#if lang === 'ru'}
                            <span class="text-muted font-size-12">(~ {templatesSum.rub} ₽)</span>
                          {/if}
                        </div>
                        {#if lang === 'ru' && paymentHistory?.correction}
                          <Button type=button color="primary" outline class="font-size-14" on:click={() => isOpenDetails = true}>
                              {$_("balance.details")}
                          </Button>
                        {/if}
                      </CardBody>
                  </Card>
                  <Card>
                    <CardBody class="position-relative">
                      <div class="position-absolute end-0 top-0 w-100 pt-3 px-3">
                        <Dropdown class="d-flex justify-content-end">
                          <DropdownToggle tag="div" class="pointer border-0 p-0 bg-transparent" caret>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                              <circle opacity="0.5" cx="11" cy="11" r="11" fill="#74788D"/>
                              <path fill="white"
                                d="M11 5C8.7944 5 7 6.68225 7 8.75H8.6C8.6 7.5095 9.6768 6.5 11 6.5C12.3232 6.5 13.4 7.5095 13.4 8.75C13.4 9.55175 13.0368 9.84875 12.2152 10.4412C11.9096 10.6618 11.5648 10.9108 11.2344 11.2198C10.1848 12.203 10.196 13.1728 10.2 13.25V14.75H11.8V13.2432C11.8 13.2253 11.8184 12.7925 12.3656 12.2802C12.6216 12.0402 12.9112 11.8317 13.1904 11.63C14.0384 11.018 15 10.325 15 8.75C15 6.68225 13.2056 5 11 5ZM10.2 15.5H11.8V17H10.2V15.5Z"/>
                            </svg>
                          </DropdownToggle>
                          <DropdownMenu end class="mt-1 p-3 font-size-14">
                            {$_("balance.hint_text")}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <CardTitle class="font-size-14 me-4">
                          {$_("balance.notification_balance_status")}
                      </CardTitle>
                      <form on:submit|preventDefault={setNotificationBalanceStatus}>
                        <FormGroup class="mb-4">
                          <div class="text-muted font-size-12 mb-1">{$_("balance.balance_reminderLimit")}</div>
                          <div class="position-relative">
                            <Input type="number" placeholder={10} min={5} bind:value={reminderLimit} required class="ps-4"/>
                            <div class="position-absolute top-0 font-size-16 p-2">$</div>
                          </div>
                        </FormGroup>
                        <FormGroup class="mb-4">
                          <div class="text-muted font-size-12 mb-1">{$_("balance.title_emails")}</div>
                          <Input type="text" placeholder={$_("balance.placeholder_emails")} bind:value={balanceEmails}/>
                        </FormGroup>
                        <Button color="success" outline class="font-size-14 d-flex align-items-center gap-2">
                          {#if notificationBalanceStatuLoader}
                            <Spinner size="sm"/>
                          {/if}
                          {$_("save")}
                        </Button>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
            </Row>
        </Container>
    </div>

    <Modal isOpen={isOpenDetails} fade={true} toggle={() => isOpenDetails = false} size="md" centered>
      <ModalHeader toggle={() => isOpenDetails = false} class="border-bottom-0 pb-0 align-items-start">
        <span class="fw-600 pe-5">
          {$_('balance.details_title')}
        </span>
      </ModalHeader>
      <ModalBody>
        <table class="table align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th class="col-6 fw-600 py-1 border-bottom">{$_("payments.table.channelId")}</th>
              <th class="col-6 text-end fw-600 py-1 border-bottom">{$_("payments.table.amount")}, $</th>
            </tr>
          </thead>
        </table>
        <div class="paymentHistoryTbody mb-3">
          <table class="table align-middle table-nowrap mb-0">
            <tbody>
              {#if paymentHistory?.channels.length}
                {#each paymentHistory.channels as channel}
                  <tr>
                    <td class="col-6 py-1 border-bottom">{channel.id}</td>
                    <td class="col-6 text-end py-1 border-bottom">{channel.sum}</td>
                  </tr>
                {/each}
              {/if}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
{/if}

<style>
  .paymentHistoryTbody {
    overflow-y: auto;
    max-height: 59vh;
  }

  .paymentHistoryTbody::-webkit-scrollbar {
    width: 3px;
  }

  .paymentHistoryTbody::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .paymentHistoryTbody::-webkit-scrollbar-thumb {
    background-color: #DBDBDD;
  }

  .paymentHistoryTbody::-webkit-scrollbar-button {
    height: 5px;
    background-color: #fff;
  }
</style>
